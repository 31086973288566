import styled from '@emotion/styled';

import { isTouchDevice } from '@app/services/device';

const CurrentTimeHoverTooltip = styled.div<{
  isVisible: boolean;
  isTransitionSlow: boolean;
  x: number;
  tooltipArrowPosition: string;
  barHeight: string;
}>`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: ${props =>
    props.isTransitionSlow ? 'opacity 1s' : 'opacity 0.2s'};
  position: absolute;
  left: ${props => `${props.x}px`};
  transform: translate(-50%, -100%);
  min-width: 69px;
  text-align: center;
  padding: 0 5px;
  background-color: ${props =>
    isTouchDevice() ? 'transparent' : props.theme.color.darkText};
  color: white;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  line-height: 24px;

  ${() =>
    isTouchDevice() &&
    `
    bottom: 5px;  
    &::before {
      content: '';
      position: absolute;
      height: 150px;
      width: 100vw;
      bottom: -21px;
      padding: 0 100vw;
      margin-left: -100vw;
      opacity: 0.6;
      background-image: linear-gradient(
        rgba(255, 0, 0, 0),
        50%,
        rgba(0, 0, 0, 1)
      );
      z-index: -1;
      pointer-events: none;
    }
    `};

  &::after {
    content: '';
    position: absolute;
    left: calc(
      ${props => props.tooltipArrowPosition} - ${props => props.barHeight}
    );
    top: 100%;
    width: 0;
    height: 0;
    z-index: -1;
    border-left: ${props => props.barHeight} solid transparent;
    border-right: ${props => props.barHeight} solid transparent;
    border-top: ${props => props.barHeight} solid
      ${props => (isTouchDevice() ? 'transparent' : props.theme.color.darkText)};
  }
`;

export default CurrentTimeHoverTooltip;
