import {
  MouseEvent,
  RefObject,
  TouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';

import { isPointerDevice, isTouchDevice } from '@app/services/device';
import {
  clientSupportsHLS,
  PlayerControlInterface,
} from '@app/services/player';
import { secondsToHms } from '@app/services/utils';

import useProgressBar from '@app/hooks/player/useProgressBar';
import useAppSelector from '@app/hooks/utils/useAppSelector';
import useWindowSize from '@app/hooks/utils/useWindowSize';

import CurrentTimeHoverTooltip from '@app/components/player/player-controls/CurrentTimeHoverTooltip';

type ProgressBarProps = {
  playerControlInterface: PlayerControlInterface;
  barHeight?: string;
  draggingRef?: RefObject<boolean>;
  setDragging?: (dragging: boolean) => void;
  dragging?: boolean;
  isLoading?: boolean;
  disableSeeking?: boolean;
  disableTrickplay?: boolean;
  handleIsSeekingOnProgressBar: (isSeeking: boolean) => void;
};

const ProgressBar = ({
  playerControlInterface,
  barHeight = '8px',
  disableSeeking = false,
  disableTrickplay = false,
  dragging = null,
  draggingRef = null,
  setDragging = null,
  isLoading = false,
  handleIsSeekingOnProgressBar,
}: ProgressBarProps) => {
  // Update when the window size changes
  useWindowSize();

  const isAdmin = useAppSelector(state => state.user.isAdmin);

  const [currentTime, duration, currentBuffer, doSetCurrentTime] =
    useProgressBar(playerControlInterface);

  const thumbnailRef = useRef(null);
  const progressBarRef = useRef(null);
  const tooltipRef = useRef(null);
  const [tooltipX, setTooltipX] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isTooltipTransitionSlow, setIsTooltipTransitionSlow] = useState(false);
  const [tooltipTime, setTooltipTime] = useState(secondsToHms(0));
  const [tooltipArrowPosition, setTooltipArrowPosition] = useState('50%');
  const [currentThumbElementStyles, setCurrentThumbElementStyles] =
    useState(null);
  const [thumbnailX, setThumbnailX] = useState(0);
  const [hasThumbnails, setHasThumbnails] = useState(false);

  const showTooltipOnDrag = !!draggingRef;

  useEffect(() => {
    handleIsSeekingOnProgressBar(showTooltip);
  }, [showTooltip]);

  useEffect(() => {
    const initThumbnails = async () => {
      if (!clientSupportsHLS()) {
        setHasThumbnails(false);
        try {
          const thumbPlugin = playerControlInterface.getPlugin(
            'thumbnails',
          ) as any;
          const thumb = await thumbPlugin.get(0);
          if (thumb) {
            setHasThumbnails(true);
          }
        } catch (error) {
          // no thumbnails
        }
      }
    };
    if (!disableTrickplay && (isAdmin || process.env.FEATURE_FLAG_TRICKPLAY)) {
      initThumbnails();
    }
  }, []);

  const handleDebouncedMouseLeave = useCallback(
    debounce(async () => {
      setIsTooltipTransitionSlow(false);
      setShowTooltip(false);
    }, 100),
    [],
  );

  const getPercentageOfDuration = (timePoint: number) => {
    if (timePoint && timePoint > 0) {
      return timePoint / duration;
    }
    return 0;
  };

  const getProgressBarWidth = () => {
    if (progressBarRef.current) {
      // Refs not available in componentDidMount
      return progressBarRef.current.offsetWidth;
    }
    // so, for Storybook only, we assign a default width value via props
    return 0;
  };

  const getProgressPixels = (timePoint: number) => {
    try {
      const progressBarWidth = getProgressBarWidth();
      const percentageOfDuration = getPercentageOfDuration(timePoint);
      return progressBarWidth * percentageOfDuration;
    } catch (error) {
      return 0;
    }
  };

  const getStartAndEndBuffer = () => {
    try {
      const bufferStart = getProgressPixels(currentBuffer?.start(0));
      const bufferEnd = getProgressPixels(currentBuffer?.end(0));
      return {
        bufferStart,
        bufferEnd,
      };
    } catch (error) {
      return {
        bufferStart: null,
        bufferEnd: null,
      };
    }
  };

  const getPointerPositionOnProgressBar = (e: MouseEvent<HTMLDivElement>) => {
    const progressBar = progressBarRef.current.getBoundingClientRect();
    const pointerPosition = e.clientX - progressBar.left;
    return pointerPosition;
  };

  const getSecondsFromPointerPosition = (clientX: number) => {
    if (!progressBarRef.current) {
      return 0;
    }
    const progressBarWidth = getProgressBarWidth();
    const fractionOfDuration = clientX / progressBarWidth;
    return duration * fractionOfDuration;
  };

  const doSeek = (e: MouseEvent<HTMLDivElement>) => {
    if (!disableSeeking) {
      const positionToSetTo = getSecondsFromPointerPosition(
        getPointerPositionOnProgressBar(e),
      );
      doSetCurrentTime(positionToSetTo);
      setTimeout(() => setShowTooltip(false), 500);
    }
  };

  const doShowTooltip = async (e: MouseEvent<HTMLDivElement>) => {
    if (hasThumbnails) {
      const pointerPosition = getPointerPositionOnProgressBar(e);
      const thumbPlugin = playerControlInterface.getPlugin('thumbnails') as any;
      const tooltipTimeInSeconds =
        getSecondsFromPointerPosition(pointerPosition);
      try {
        const thumb = await thumbPlugin.get(tooltipTimeInSeconds);
        await thumb.load();
        const thumbElement = thumb.element(window.innerWidth > 767 ? 240 : 160);
        if (thumbElement?.style) {
          setCurrentThumbElementStyles({
            backgroundImage: thumbElement.style.backgroundImage,
            backgroundPositionX: thumbElement.style.backgroundPositionX,
            backgroundPositionY: thumbElement.style.backgroundPositionY,
            backgroundSize: thumbElement.style.backgroundSize,
            height: thumbElement.style.height,
            width: thumbElement.style.width,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    setIsTooltipTransitionSlow(false);
    setShowTooltip(true);
  };

  const doSetTooltipX = (e: MouseEvent<HTMLDivElement>) => {
    const pointerPosition = getPointerPositionOnProgressBar(e);
    const progressBar = progressBarRef.current.getBoundingClientRect();
    if (
      pointerPosition > 0 &&
      pointerPosition < progressBar.right - progressBar.left
    ) {
      const tooltipArrowsWidthHalved = parseInt(barHeight, 10);
      const tooltipWidth = tooltipRef?.current?.offsetWidth;
      const tooltipWidthHalved = tooltipWidth / 2;
      const tooltipClipsProgressBarLeft =
        e.clientX - progressBar.left < tooltipWidthHalved;
      const tooltipClipsProgressBarRight =
        e.clientX > progressBar.right - tooltipWidthHalved;
      if (tooltipClipsProgressBarLeft) {
        setTooltipX(tooltipWidthHalved);
        const relativeMousePos = e.clientX - progressBar.left;
        setTooltipArrowPosition(
          `${Math.floor(
            ((relativeMousePos < tooltipArrowsWidthHalved
              ? tooltipArrowsWidthHalved
              : relativeMousePos) /
              tooltipWidth) *
              100,
          )}%`,
        );
      } else if (tooltipClipsProgressBarRight) {
        setTooltipX(progressBar.right - progressBar.left - tooltipWidthHalved);
        // remove the progress so the equation is the same as (e.clientX / tooltipWidth) * 100
        const offset = progressBar.right - tooltipWidth;
        setTooltipArrowPosition(
          `${Math.floor(
            ((e.clientX - offset > tooltipWidth - tooltipArrowsWidthHalved
              ? tooltipWidth - tooltipArrowsWidthHalved
              : e.clientX - offset) /
              tooltipWidth) *
              100,
          )}%`,
        );
      } else {
        setTooltipX(e.clientX - progressBar.left);
        setTooltipArrowPosition('50%');
      }
      setTooltipTime(
        secondsToHms(getSecondsFromPointerPosition(pointerPosition)),
      );
    }
  };

  const doSetThumbnailX = (e: MouseEvent<HTMLDivElement>) => {
    if (hasThumbnails) {
      const progressBar = progressBarRef.current.getBoundingClientRect();
      const thumbnailRefWidthHalved = thumbnailRef.current.offsetWidth / 2;
      const thumbnailPosition = e.clientX - progressBar.left;
      if (thumbnailPosition < thumbnailRefWidthHalved) {
        setThumbnailX(thumbnailRefWidthHalved);
      } else if (e.clientX > progressBar.right - thumbnailRefWidthHalved) {
        setThumbnailX(progressBar.width - thumbnailRefWidthHalved);
      } else {
        setThumbnailX(thumbnailPosition);
      }
    }
  };

  const doSetOverridePositionOnDrag = (e: TouchEvent<HTMLDivElement>) => {
    if (
      e.type === 'touchstart' ||
      e.type === 'touchmove' ||
      e.type === 'touchend' ||
      e.type === 'touchcancel'
    ) {
      const touchEvent = e.changedTouches[0] as any;
      const pixelsFromLeft = getPointerPositionOnProgressBar(touchEvent);

      if (pixelsFromLeft > 0 && pixelsFromLeft < getProgressBarWidth()) {
        setOverridePosition(pixelsFromLeft);
      } else if (pixelsFromLeft > getProgressBarWidth()) {
        setOverridePosition(progress);
      } else if (pixelsFromLeft < 0) {
        setOverridePosition(0);
      }
    }
  };

  const progress = getProgressPixels(currentTime);
  const [overridePosition, setOverridePosition] = useState(progress);
  const { bufferStart, bufferEnd } = getStartAndEndBuffer();

  // Keep override position up to date with progress in case it changes with time skips
  useEffect(() => {
    if (!dragging) {
      setOverridePosition(progress);
    }
  }, [progress]);

  const startDrag = (e: TouchEvent<HTMLDivElement>) => {
    document.addEventListener('touchmove', evt => {
      if (e.touches[0]?.clientY !== evt.changedTouches[0]?.clientY) {
        evt.preventDefault();
      }
      doDrag(evt);
    });
    document.addEventListener('touchend', endDrag);
    setDragging(true);
    doSetOverridePositionOnDrag(e);
  };

  const doDrag = e => {
    if (draggingRef.current) {
      doSetOverridePositionOnDrag(e);
      doSetTooltipX(e.changedTouches?.[0] as any);
      doShowTooltip(e.changedTouches?.[0] as any);
    }
  };

  const endDrag = e => {
    document.removeEventListener('touchend', endDrag);
    document.removeEventListener('touchmove', doDrag);
    doSetOverridePositionOnDrag(e);
    doSeek(e.changedTouches[0]);
    setShowTooltip(false);
    setTimeout(() => setDragging(false), 100);
  };

  const touchControlPosition =
    dragging || isLoading ? overridePosition : progress;

  return (
    <>
      {hasThumbnails && (
        <Thumbnail
          ref={thumbnailRef}
          offsetX={thumbnailX}
          style={currentThumbElementStyles}
          isVisible={showTooltip && isPointerDevice()}
          isTransitionSlow={isTooltipTransitionSlow}
        />
      )}
      <Container
        ref={progressBarRef}
        onClick={doSeek}
        onMouseMove={e => {
          doSetTooltipX(e);
          doSetThumbnailX(e);
          doShowTooltip(e);
        }}
        onTouchStart={e => showTooltipOnDrag && startDrag(e)}
        onMouseLeave={handleDebouncedMouseLeave}
        barHeight={barHeight}
        className="chromatic-ignore"
      >
        {(showTooltipOnDrag || isPointerDevice()) && (
          <CurrentTimeHoverTooltip
            ref={tooltipRef}
            x={tooltipX}
            isVisible={showTooltip}
            isTransitionSlow={isTooltipTransitionSlow}
            tooltipArrowPosition={tooltipArrowPosition}
            barHeight={barHeight}
          >
            {tooltipTime}
          </CurrentTimeHoverTooltip>
        )}
        {showTooltipOnDrag && isTouchDevice() && (
          <ControlDot controlPosition={touchControlPosition} />
        )}
        <Buffer start={bufferStart} end={bufferEnd} />
        <Progress
          progress={isTouchDevice() ? touchControlPosition : progress}
        />
      </Container>
    </>
  );
};

export default ProgressBar;

const Container = styled.div<{ barHeight: string }>`
  cursor: pointer;
  width: 100%;
  height: ${props => props.barHeight};
  background-color: ${props => props.theme.color.darkText};
  position: relative;
  touch-action: pan-x;

  // Remove focus highlight on touch devices
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
`;

const Buffer = styled.div<{
  start: number;
  end: number;
}>`
  height: 100%;
  background-color: ${props => props.theme.color.lightText};
  position: absolute;
  top: 0;
  bottom: 0;

  left: ${props => `${props.start}px`};
  right: ${props => `calc(100% - ${props.end}px)`};
`;

const Progress = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${props => `calc(100% - ${props.progress}px)`};
  height: 100%;
  background-color: ${props => props.theme.color.mubiBlue};
`;

const ControlDot = styled.div<{ controlPosition: number }>`
  position: absolute;
  top: -6px;
  left: ${props => `${props.controlPosition - 4}px`};
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: ${props => props.theme.color.white};
  z-index: 5;
  cursor: pointer;
`;

const Thumbnail = styled.div<{
  offsetX: number;
  isVisible: boolean;
  isTransitionSlow: boolean;
}>`
  position: absolute;
  left: ${props => props.offsetX}px;
  bottom: calc(100% + 32px); // float 8px above timestamp tooltip
  transform: translateX(-50%);
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: ${props =>
    props.isTransitionSlow ? 'opacity 1s' : 'opacity 0.2s'};
  box-shadow: 2px 0px 8px 0px ${props => props.theme.color.rgbaBlack40Opacity};
  background-color: ${props => props.theme.color.darkText};
`;
