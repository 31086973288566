import { useEffect, useState } from 'react';

import { defaultPlayerControlInterface } from '@app/services/player';

const useProgressBar = (
  playerControlInterface = defaultPlayerControlInterface,
): [number, number, any, (secondsToSetTo: number) => void] => {
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [currentBuffer, setCurrentBuffer] = useState({});

  const addControlBarListeners = () => {
    playerControlInterface.setOnProgress(onProgress as any);
    playerControlInterface.setOnTimeUpdate(onTimeUpdate);
  };

  const removeControlBarListeners = () => {
    playerControlInterface.clearOnProgress(onProgress);
    playerControlInterface.clearOnTimeUpdate(onTimeUpdate);
  };

  const doSetCurrentTime = (secondsToSetTo: number) => {
    playerControlInterface.setCurrentTime(secondsToSetTo);
  };

  const onProgress = () => {
    setCurrentBuffer(playerControlInterface.getBuffered() as any);
  };

  const onTimeUpdate = () => {
    setCurrentTime(playerControlInterface.getCurrentTime());
  };

  useEffect(() => {
    if (playerControlInterface) {
      setDuration(playerControlInterface.getDuration());
      addControlBarListeners();
    }

    return () => {
      if (playerControlInterface) {
        removeControlBarListeners();
      }
    };
  }, [playerControlInterface]);

  return [currentTime, duration, currentBuffer, doSetCurrentTime];
};

export default useProgressBar;
