import { useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import styled from '@emotion/styled';

import useDidUpdateEffect from '@app/hooks/utils/useDidUpdateEffect';

type UserActiveContainerProps = {
  children: (props: { isUserActive: boolean }) => JSX.Element;
  isEnabled?: boolean;
  className?: string;
  millisecsUntilInactive?: number;
};

const UserActiveContainer = ({
  children,
  isEnabled = true,
  className = '',
  millisecsUntilInactive = 3500,
}: UserActiveContainerProps) => {
  const userActiveTimeoutId = useRef(null);
  const [isUserActive, setIsUserActive] = useState(false);

  useDidUpdateEffect(() => {
    if (isEnabled && !isUserActive) {
      onInteraction();
    }
  }, [isEnabled]);

  const onInteraction = () => {
    if (!isUserActive) {
      setIsUserActive(true);
    }

    doClearUserActiveTimeout();
    startInactiveCountdown();
  };

  const startInactiveCountdown = () => {
    if (!userActiveTimeoutId.current) {
      userActiveTimeoutId.current = setTimeout(() => {
        setIsUserActive(false);
      }, millisecsUntilInactive);
    }
  };

  const doClearUserActiveTimeout = () => {
    clearTimeout(userActiveTimeoutId.current);
    userActiveTimeoutId.current = null;
  };

  const mouseMoved = () => {
    onInteraction();
  };

  const touchEnded = () => {
    onInteraction();
  };

  if (!isEnabled) {
    return <Container>{children({ isUserActive: false })}</Container>;
  }

  return (
    <Container
      className={className}
      onMouseMove={throttle(mouseMoved, 500)}
      onTouchEnd={touchEnded}
    >
      {children({ isUserActive })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default UserActiveContainer;
